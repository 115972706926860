<template>
	<v-flex shrink>
		<w-text-input
			:disabled="disabled"
			:label="labelText"
			:max="max"
			:min="min"
			:readonly="readonly"
			:suffix="suffix"
			:required="required"
			type="number"
			:value="isFocused ? number : displayedNumber"
			@blur="onBlur($event)"
			@focus="onFocus($event)"
			@keypress="onKeypress($event)"
			@paste="onPaste($event)"
			@input="number = $event"
		/>
	</v-flex>
</template>

<script>
/**
 * @displayName w-number-input
 */
export default {
	name: 'WNumberInput',
	props: {
		disabled: {
			default: false,
			required: false,
			type: Boolean
		},
		label: {
			default: undefined,
			required: false,
			type: String
		},
		readonly: {
			default: false,
			type: Boolean,
			required: false
		},
		required: {
			default: false,
			required: false,
			type: Boolean
		},
		min: {
			default: undefined,
			required: false,
			type: Number
		},
		max: {
			default: undefined,
			required: false,
			type: Number
		},
		suffix: {
			default: undefined,
			required: false,
			type: String
		},
		/**
		 * @model
		 */
		value: {
			default: null,
			type: [Number, String],
			required: false
		}
	},
	data: function () {
		return {
			isFocused: false,
			oldVal: 0
		}
	},
	computed: {
		displayedNumber: function () {
			if (!this.value) {
				return ''
			}
			return parseInt(this.value)
		},
		labelText: function () {
			if (!this.label) {
				return this.$t('number')
			}
			return this.label
		},
		number: {
			get: function () {
				if (this.value && this.value !== '') {
					return parseInt(this.value)
				} else {
					return ''
				}
			},
			set: function (val) {
				if (
					typeof val === 'string' &&
					val.length > 0 &&
					(!this.min || (this.min && parseInt(val) >= this.min)) &&
					(!this.max || (this.max && parseInt(val) <= this.max))
				) {
					val = parseFloat(val.replace(',', '.').replace(/[^0-9. ]+/g, ''))
				} else if (typeof val === 'string' && val.trim().length === 0) {
					val = null
				} else if (this.min && parseInt(val) < this.min) {
					val = this.min
				} else if (this.max && parseInt(val) > this.max) {
					val = null
				}
				this.$emit('input', val)
			}
		},
		minValue: function () {
			let result = null

			if (this.min != null) {
				result = parseInt(this.min)
			}
			if (isNaN(result)) {
				result = null
			}

			return result
		},
		maxValue: function () {
			let result = null

			if (this.max != null) {
				result = parseInt(this.max)
			}
			if (isNaN(result)) {
				result = null
			}

			return result
		}
	},
	methods: {
		onBlur: function () {
			this.isFocused = false
		},
		onFocus: function () {
			if (!this.readonly && !this.disabled) {
				this.isFocused = true
			}
		},
		onKeypress: function (event) {
			const key = event.key

			if (key.match(/[^0-9. ]+/g)) {
				event.preventDefault()
			}
		},
		onPaste: function (event) {
			const clipboardValue = event.clipboardData.getData('text')
			const number = parseInt(clipboardValue)
			if (isNaN(number)) {
				event.preventDefault()
			} else {
				if (this.minValue != null && this.minValue > number) {
					event.preventDefault()
				}
				if (this.maxValue != null && this.maxValue < number) {
					event.preventDefault()
				}
			}
		}
	}
}
</script>
